import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { loadAdventurePlaceData, loadAdventurePlaces, createAdventurePlace, updateAdventurePlace } from "../../api/adventurePlace";
import { createAdventureNote, loadAdventureNotesList, updateAdventureNote } from "../../api/adventureNotes";
import { loadAdventureChaptersList } from "../../api/adventureChapters";
import { loadAdventurePersonsList } from "../../api/adventurePersons";
import { Container, Grid, Header, Icon, Input } from "semantic-ui-react";
import { ADVENTURE_PAGE_LINK, ADVENTURE_PLACE_PAGE_LINK, ADVENTURE_PLACES_HASH } from "../../router/links";
import MapContainer from "./components/mapContainer";
import CreatePlaceDialog from './components/createPlaceDialog';
import EditNoteDialog from './components/editNoteDialog';

const AdventurePlacePage = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    
    const [form, setForm] = useState({});
    const [name, setName] = useState('');
    const [chapters, setChapters] = useState([]);
    const [persons, setPersons] = useState([]);
    const [childPlaces, setChildPlaces] = useState([]);
    const [notes, setNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editNoteDialog, setEditNoteDialog] = useState({ isOpen: false });
    const [createPlaceDialog, setCreatePlaceDialog] = useState({ isOpen: false });

    const convertToOptions = (entry) => {
        return {
            key: entry.id,
            text: entry.name,
            value: entry.id
        };
    };

    const loadChapters = (adventureId) => {
        if (adventureId) {
            loadAdventureChaptersList(adventureId)
                .then(result => setChapters(result.map(convertToOptions)));
        }
    };

    const loadPersons = (adventureId) => {
        if (adventureId) {
            loadAdventurePersonsList(adventureId)
                .then(result => setPersons(result.map(convertToOptions)));
        }
    };

    const reloadChildPlaces = (place) => {
        loadAdventurePlaces(place.adventureId, place.id)
            .then(result => {
                setChildPlaces(result);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const reloadNotes = (place) => {
        loadAdventureNotesList(place.adventureId, null, null, place.id)
            .then(result => setNotes(result));
    };

    const getData = () => {
        setIsLoading(true);
        loadAdventurePlaceData(id)
            .then(result => {
                setForm(result);
                setName(result.name || '');
                reloadChildPlaces(result);
                reloadNotes(result);
                loadChapters(result.adventureId);
                loadPersons(result.adventureId);
            })
            .catch(() => setIsLoading(false));
    }
    useEffect(getData, [id]);

    const contextMenuItems = [
        {
            icon: 'map marker',
            name: 'Новая локация',
            onClick: (x, y) => setCreatePlaceDialog({
                isOpen: true,
                x, 
                y
            })
        },
        {
            icon: 'sticky note outline',
            name: 'Новая заметка',
            onClick: (x, y) => setEditNoteDialog({
                isOpen: true,
                form: {
                    adventure: {
                        id: form.adventureId,
                        name: form.adventureName
                    },
                    place: {
                        id: id,
                        name: form.name
                    },
                    placeX: x,
                    placeY: y
                }
            })
        }
    ];
    
    const closeEditNoteDialog = () => setEditNoteDialog({ isOpen: false });

    const onEditNoteSave = (data) => {
        setIsLoading(true);
        (data.id ? updateAdventureNote(data.id, data) : createAdventureNote(data))
            .then(() => {
                closeEditNoteDialog();
                reloadNotes(form);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };
    
    const closeCreatePlaceDialog = () => setCreatePlaceDialog({ isOpen: false });

    const onCreatePlaceSave = (data) => {
        setIsLoading(true);
        createAdventurePlace(form.adventureId, data)
            .then(() => {
                closeCreatePlaceDialog();
                reloadChildPlaces(form);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const onRename = () => {
        updateAdventurePlace(form.id, {
            ...form,
            name
        });
    };

    const mapPoints = childPlaces.map((place) => ({
        icon: 'map marker',
        title: place.name,
        x: place.parentPlaceX,
        y: place.parentPlaceY,
        contextItems: [
            {
                content: <Header as='h4'>{place.name}</Header>,
                onClick: () => navigate(ADVENTURE_PLACE_PAGE_LINK.replace(':id', place.id))
            },
        ],
        onMove: (x, y) => updateAdventurePlace(place.id, {
            ...place,
            parentPlaceX: x,
            parentPlaceY: y
        }).then(() => reloadChildPlaces(form))
    })).concat(notes.map((note) => ({
        icon: 'sticky note outline',
        title: note.text && note.text.length > 100 ? `${note.text.substring(100)}...` : note.text,
        x: note.placeX,
        y: note.placeY,
        contextItems: [
            {
                content: (
                    <>
                        <Header as='h5'>{note.text && note.text.length > 100 ? `${note.text.substring(100)}...` : note.text}</Header>
                        {note.chapter && <p>
                            <Icon name="list ol"/>
                            {note.chapter.name}
                        </p>}
                        {note.person && <p>
                            <Icon name="group"/>
                            {note.person.name}
                        </p>}
                    </>
                ),
                icon: 'edit',
                name: 'Править заметку',
                onClick: () => setEditNoteDialog({
                    isOpen: true,
                    form: note
                })
            },
        ],
        onMove: (x, y) => onEditNoteSave({
            ...note,
            placeX: x,
            placeY: y
        })
    })));

    return (
        <Container>
            <Grid divided='vertically'>
                <Grid.Row className='adventure-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Header size="medium" className="adventure-subpage-header">
                                <b>Приключение</b>
                            </Header>
                        </div>
                        <div className='header-title'>
                            <Header size="medium" className="adventure-subpage-header" style={{ marginLeft: 10 }}>
                                {form.adventureName}
                            </Header>
                        </div>
                    </div>
                </Grid.Row>
                <Grid.Row className='adventure-header adventure-place-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Link to={ADVENTURE_PAGE_LINK.replace(':id', form.adventureId) + ADVENTURE_PLACES_HASH} className="ui basic circular icon left labeled button">
                                <Icon name="arrow left"/>
                                Локации
                            </Link>
                        </div>
                        <div className='header-title'>
                            <Input 
                                transparent 
                                size='big'
                                className='title-input'
                                disabled={isLoading}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={onRename}
                            />
                        </div>
                    </div>
                    {form.parentPlaceId && form.parentPlaceName && (
                        <div className='header-up'>
                            <Link to={ADVENTURE_PLACE_PAGE_LINK.replace(':id', form.parentPlaceId)} className="ui basic circular icon left labeled button">
                                <Icon name="level up"/>
                                {form.parentPlaceName}
                            </Link>
                        </div>
                    )}
                </Grid.Row>
            </Grid>
            {form.id && <Grid.Row>
                <Grid.Column>
                    <MapContainer 
                        key={"map-place-" + form.id}
                        mapUrl={'/api/v1/files/adventurePlace/' + form.fileId}
                        points={mapPoints}
                        contextMenuItems={contextMenuItems}
                    />
                </Grid.Column>
            </Grid.Row>}

            <CreatePlaceDialog 
                isOpen={createPlaceDialog.isOpen}
                disabled={isLoading}
                title='Создание новой локации'
                parentId={id}
                parentX={createPlaceDialog.x}
                parentY={createPlaceDialog.y}
                onSave={onCreatePlaceSave}
                onCancel={closeCreatePlaceDialog}
            />

            <EditNoteDialog 
                isOpen={editNoteDialog.isOpen}
                disabled={isLoading}
                chapters={chapters}
                persons={persons}
                entry={editNoteDialog.form}
                onSave={onEditNoteSave}
                onCancel={closeEditNoteDialog}
            />
        </Container>
    );
};

export default AdventurePlacePage;