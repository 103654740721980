import React, { useEffect, useState } from "react";
import { Icon, Menu, MenuItem } from 'semantic-ui-react';
import './customContextMenu.css';

const CustomContextMenu = ({
    items,
    isOpen,
    x,
    y
}) => {
    const [visibility, setVisibility] = useState('hidden');

    useEffect(
        () => setVisibility(isOpen ? 'visible' : 'hidden'), 
        [isOpen]
    );

    const onItemClick = (e, item) => {
        item.onClick(x, y, e.pageX, e.pageY);
    };

    const menuItems = items && items.map((item, index) => (
        <MenuItem 
            key={'context-item-' + index}
            onClick={(e) => onItemClick(e, item)}
        >
            {item.content ? item.content : (
                <>
                    {item.icon && <Icon name={item.icon} />}
                    {item.name}
                </>
            )}
        </MenuItem>
    ))

    return (
        <Menu
            vertical
            visibility={visibility}
            className="context-menu"
            style={{
                visibility,
                left: x,
                top: y
            }}
        >
            {menuItems}
        </Menu>
    );
};

export default CustomContextMenu;