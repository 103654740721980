import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, ItemImage, List, ListItem } from "semantic-ui-react";
import { ADVENTURE_CHAPTER_PAGE_LINK, ADVENTURE_PERSON_PAGE_LINK } from "../../../router/links";
import EditNoteDialog from "./editNoteDialog";
import { loadAdventureChaptersList } from "../../../api/adventureChapters";
import { loadAdventurePersonsList } from "../../../api/adventurePersons";
import { createAdventureNote, deleteAdventureNote, updateAdventureNote } from "../../../api/adventureNotes";
import ConfirmationDialog from "../../../modals/confirmationDialog";

const NotesList = ({
    adventureId,
    chapterId,
    personId,
    placeId,
    entries,
    disabled,
    onReload
}) => {
    const [editDialog, setEditDialog] = useState({ isOpen: false });
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [isLoading, setIsLoading] = useState(false);
    const [chapters, setChapters] = useState([]);
    const [persons, setPersons] = useState([]);

    const convertToOptions = (entry) => {
        return {
            key: entry.id,
            text: entry.name,
            value: entry.id
        };
    };

    const loadOptions = () => {
        if (adventureId) {
            loadAdventureChaptersList(adventureId).then(result => setChapters(result.map(convertToOptions)));
            loadAdventurePersonsList(adventureId).then(result =>  setPersons(result.map(convertToOptions)));
        }
    };
    useEffect(loadOptions, [adventureId]);

    const cancelEdit = () => setEditDialog({ isOpen: false });

    const confirmEdit = (data) => {
        setIsLoading(true);

        (data.id ? updateAdventureNote(data.id, data) : createAdventureNote(data))
            .then(() => {
                setIsLoading(false);
                onReload();
                cancelEdit();
            })
            .catch(() => setIsLoading(false));
    };

    const editNote = (entry) => setEditDialog({ entry, isOpen: true });

    const createNote = () => editNote({
        adventure: { id: adventureId },
        chapter: chapterId ? { id: chapterId } : null,
        person: personId ? { id: personId } : null,
        place: placeId ? { id: placeId } : null,
    });

    const handleDelete = (id) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить заметку?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventureNote(id)
            .then(() => {
                onReload();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const items = entries.map((entry, ind) => (
        <Item key={'note-' + ind}>
            <ItemImage style={{ width: 30 }}>
                <Icon name="sticky note outline" size='big' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <List horizontal>
                        {entry.chapter ? (
                            <ListItem 
                                icon='list ol'
                                content={
                                <Link to={ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.chapter.id)}>
                                    {entry.chapter.name}
                                </Link>
                                }
                            />
                        ) : null}
                        {entry.person ? (
                            <ListItem 
                                icon='group'
                                content={
                                    <Link to={ADVENTURE_PERSON_PAGE_LINK.replace(':id', entry.person.id)}>
                                        {entry.person.name}
                                    </Link>
                                }
                            />
                        ) : null}
                        {entry.place ? (
                            <ListItem 
                                icon='map'
                                content={
                                    <Link to=''>
                                        {entry.place.name}
                                    </Link>
                                }
                            />
                        ) : null}
                        <ListItem>
                            <Icon 
                                className="remove-button"
                                name="pencil alternate" 
                                onClick={() => editNote(entry)}
                            />
                        </ListItem>
                        <ListItem>
                            <Icon 
                                className="remove-button"
                                name="trash alternate" 
                                onClick={() => handleDelete(entry.id)}
                            />
                        </ListItem>
                    </List>
                </ItemHeader>
                <ItemDescription>
                    {entry.text}
                </ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size="medium" className="inline-title">
                            Заметки
                        </Header>
                        <Button
                            circular
                            positive
                            icon='plus'
                            size="tiny"
                            className="inline-title"
                            disabled={isLoading || disabled}
                            onClick={createNote}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <ItemGroup divided>{items}</ItemGroup>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <EditNoteDialog
                entry={editDialog.entry}
                chapters={chapters}
                persons={persons}
                isOpen={editDialog.isOpen}
                disabled={isLoading || disabled}
                onCancel={cancelEdit}
                onSave={confirmEdit}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление заметки'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </>
    );
};

export default NotesList;