import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";

const CreatePlaceDialog = ({
    isOpen,
    disabled,
    title,
    parentId,
    parentX,
    parentY,
    onCancel,
    onSave
}) => {
    const fileRef = useRef();
    const [name, setName] = useState('');

    useEffect(() => {
        setName('');
    }, [isOpen]);

    const saveData = () => {
        const file = fileRef.current && fileRef.current.files && fileRef.current.files[0];
        const form = new FormData();
        form.append('name', name);
        parentId && form.append('parentPlaceId', parentId);
        parentX && form.append('parentPlaceX', parentX);
        parentY && form.append('parentPlaceY', parentY);
        form.append('file', file, file ? file.name : 'file');
        onSave(form);
    };

    return (
        <Modal size="tiny" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Название</label>
                        <input
                            value={name}
                            disabled={disabled}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Изображение</label>
                        <input
                            type="file"
                            disabled={disabled}
                            ref={fileRef}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    onClick={onCancel}
                >
                    Отмена
                </Button>
                <Button 
                    positive 
                    disabled={disabled}
                    onClick={saveData}
                >
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreatePlaceDialog;