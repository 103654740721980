import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Container, Grid, Icon, Input, Tab } from 'semantic-ui-react';
import { ADVENTURE_CHAPTERS_HASH, ADVENTURE_INFO_HASH, ADVENTURE_PERSONS_HASH, ADVENTURE_PLACES_HASH, ADVENTURES_LIST_LINK } from '../../router/links';
import AdventureInfoTab from './tabs/adventureInfoTab';
import AdventureChaptersTab from './tabs/adventureChaptersTab';
import AdventurePersonsTab from './tabs/adventurePersonsTab';
import { loadAdventureData, updateAdventureField } from '../../api/adventures';
import { loadAdventureChaptersList } from '../../api/adventureChapters';
import { loadAdventurePersonsList } from '../../api/adventurePersons';
import AdventurePlacesTab from './tabs/adventurePlacesTab';
import { loadAdventurePlaces } from '../../api/adventurePlace';

const AdventurePage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    
    const [activeTab, setActiveTab] = useState(0);
    const [adventure, setAdventure] = useState({});
    const [chapters, setChapters] = useState([]);
    const [persons, setPersons] = useState([]);
    const [places, setPlaces] = useState([]);
    const [name, setName] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isChaptersLoading, setIsChaptersLoading] = useState(false);
    const [isPersonsLoading, setIsPersonsLoading] = useState(false);
    const [isPlacesLoading, setIsPlacesLoading] = useState(false);

    const isLoading = isDataLoading || isChaptersLoading || isPersonsLoading;

    const reloadChapters = () => {
        if (id) {
            setIsChaptersLoading(true);
            loadAdventureChaptersList(id)
                .then(result => {
                    setIsChaptersLoading(false);
                    setChapters(result);
                })
                .catch(() => setIsChaptersLoading(false));
        }
    };

    const reloadPersons = () => {
        if (id) {
            setIsPersonsLoading(true);
            loadAdventurePersonsList(id)
                .then(result => {
                    setIsPersonsLoading(false);
                    setPersons(result);
                })
                .catch(() => setIsPersonsLoading(false));
        }
    };

    const reloadPlaces = () => {
        if (id) {
            setIsPlacesLoading(true);
            loadAdventurePlaces(id)
                .then(result => {
                    setIsPlacesLoading(false);
                    setPlaces(result);
                })
                .catch(() => setIsPlacesLoading(false));
        }
    };

    const getData = () => {
        setIsDataLoading(true);
        loadAdventureData(id)
            .then(result => {
                setIsDataLoading(false);

                setAdventure(result);
                setName(result.name);

                reloadChapters();
                reloadPersons();
                reloadPlaces();
            })
            .catch(() => {
                setIsDataLoading(false);
            });
    }
    useEffect(getData, [id]);

    const saveField = (fieldName, value) => {
        setIsDataLoading(true);
        updateAdventureField(id, fieldName, value)
            .then(() => setIsDataLoading(false))
            .catch(() => setIsDataLoading(false));
    };

    const panes = [
        {
            tag: ADVENTURE_INFO_HASH,
            render: () => (
                <AdventureInfoTab 
                    key={'adventure-info-' + (adventure.id || 'none')}
                    disabled={isLoading}
                    adventure={adventure} 
                    onSaveField={saveField}
                />
            )
        },
        {
            tag: ADVENTURE_CHAPTERS_HASH,
            render: () => (
                <AdventureChaptersTab
                    key={'adventure-chapters-' + (adventure.id || 'none')}
                    disabled={isLoading}
                    adventureId={id}
                    chapters={chapters} 
                    onReload={reloadChapters}
                />
            )
        },
        {
            tag: ADVENTURE_PERSONS_HASH,
            render: () => (
                <AdventurePersonsTab
                    key={'adventure-persons-' + (adventure.id || 'none')}
                    disabled={isLoading}
                    adventureId={id}
                    persons={persons} 
                    onReload={reloadPersons}
                />
            )
        },
        {
            tag: ADVENTURE_PLACES_HASH,
            render: () => (
                <AdventurePlacesTab
                    key={'adventure-places-' + (adventure.id || 'none')}
                    disabled={isLoading}
                    adventureId={id}
                    places={places}
                    onReload={reloadPlaces}
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    return (
        <Container>
            <Grid>
                <Grid.Row className='adventure-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Link to={ADVENTURES_LIST_LINK} className="ui basic circular icon left labeled button">
                                <Icon name="arrow left"/>
                                Приключения
                            </Link>
                        </div>
                        <div className='header-title'>
                            <Input 
                                transparent 
                                size='big'
                                className='title-input'
                                disabled={isLoading}
                                value={name} 
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => saveField('name', name)}
                            />
                        </div>
                    </div>
                    <div className='header-links'>
                        <Link 
                            to={ADVENTURE_INFO_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_INFO_HASH ? ' black' : '')}
                        >
                            <Icon name="info"/>
                        </Link>
                        <Link 
                            to={ADVENTURE_CHAPTERS_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_CHAPTERS_HASH ? ' black' : '')}
                        >
                            <Icon name="list ol"/>
                        </Link>
                        <Link 
                            to={ADVENTURE_PERSONS_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_PERSONS_HASH ? ' black' : '')}
                        >
                            <Icon name="group"/>
                        </Link>
                        <Link 
                            to={ADVENTURE_PLACES_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_PLACES_HASH ? ' black' : '')}
                        >
                            <Icon name="map"/>
                        </Link>
                    </div>
                </Grid.Row>
            </Grid>
            <Tab menu={{ as: () => <></> }} panes={panes} activeIndex={activeTab} />
        </Container>
    );
};

export default AdventurePage;