import React, { useEffect, useState } from "react";
import { Form, Button, Dropdown, Modal, FormGroup } from "semantic-ui-react";
import MarkdownEditor from "../../../controls/markdownEditor";

const EditNoteDialog = ({
    isOpen,
    entry,
    chapters,
    persons,
    disabled,
    onCancel,
    onSave
}) => {
    const [chapter, setChapter] = useState(null);
    const [person, setPerson] = useState(null);
    const [text, setText] = useState('');

    useEffect(
        () => {
            setText(entry ? entry.text : '');
            setChapter(entry && entry.chapter ? entry.chapter.id : null);
            setPerson(entry && entry.person ? entry.person.id : null);
        }, 
        [isOpen]
    );

    const save = () =>
        onSave({
            ...entry,
            chapter: chapter ? { id: chapter } : null,
            person: person ? { id: person } : null,
            text
        });

    const title = entry && entry.id ? 'Редактирование заметки' : 'Создание новой заметки';

    return (
        <Modal size="small" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormGroup widths='equal'>
                        <Form.Field>
                            <label>Глава</label>
                            <Dropdown
                                search
                                selection
                                options={[{},].concat(chapters)}
                                value={chapter}
                                onChange={(e, { value }) => setChapter(value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Персонаж</label>
                            <Dropdown
                                search
                                selection
                                options={[{},].concat(persons)}
                                value={person}
                                onChange={(e, { value }) => setPerson(value)}
                            />
                        </Form.Field>
                    </FormGroup>
                    <Form.Field>
                        <MarkdownEditor
                            value={text}
                            disabled={disabled}
                            onChange={setText}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel} disabled={disabled}>
                    Отмена
                </Button>
                <Button positive onClick={save} disabled={disabled}>
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EditNoteDialog;